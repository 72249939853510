import React from "react";
import Head from "next/head";
import { duwunCoverLogo } from '/utils/common';

function SeoMeta(props){
    const { url, type, title, description, image, themeColor, section, keywords, pubDate, lastMod } = props;
    let pubDateSafe = pubDate ? pubDate : '2018-12-12T09:45:36Z';
    let lastModSafe = lastMod ? lastMod : '2018-12-12T09:45:36Z';
    let themeColorSafe = themeColor ? themeColor : '#F9461E';
    let keywordsSafe = keywords ? keywords : 'News, Media, Entertainment, Sports, Lifestyle';
    let descriptionSafe = description ? description : "Read today’s news updates, exclusive interviews and viral videos. Stay up to date with breaking stories sent straight to your phone."; 
    let imageSafe = image ? image : duwunCoverLogo;
    // https://github.com/vercel/next.js/issues/17721
    // react component doesn't work in next/head in recent version
    // <Head>
    //     <CustomTitle /> // doesn't work
    //     <ThirdPartyScript /> // doesn't work
    // </Head>    
    return (
        <Head> 
            <meta property="og:url" content={url} />
            <meta property="og:type" content={type} />
            <meta property="og:image" content={imageSafe} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={descriptionSafe} />
            <link rel="canonical" href={url} />   
            <meta content={section} name="section" />
            <meta content={descriptionSafe} name="description" />
            <meta content={keywordsSafe} name="keywords" /> 
            <meta content={pubDateSafe} name="pubdate" />
            <meta content={lastModSafe} name="lastmod" />
            <meta content={themeColorSafe} name="theme-color" />
            <meta content={descriptionSafe} itemProp="description" />
        </Head>
    );
}

export default SeoMeta;